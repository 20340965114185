<template>
  <div id="transparent-body">
    <modalViewLead
      v-if="hasLoaded"
      ref="visualizar-lead"
      :userData="userData" 
      :recursosData="recursosData"
      :productsData="productsData"
      :userLevelData="userLevelData"
      :sellers="usersData" 
      :tags_props="tagsData"
      :id_lead_selected="{id: leadId}"
      :entireLead="entireLead"
      :isIframe="true"
      :newPicture="newPicture"
      :leadsUnification="leadsUnification"
      @closeModal="returnPostMensage({type: 'closeModal'})"
      @returnPostMensage="returnPostMensage"
    />
    <modalCreateLead 
      ref="add-lead"
      v-if="hasLoaded"
      :isIframe="true"
      :sellers="usersData" 
      :newLeadPhone="newLeadPhone"
      :newLeadPicture="newLeadPicture"
      :newLeadName="newLeadName"
      :leadsUnification="leadsUnification"
      @closeModal="returnPostMensage({type: 'closeModal'})"
      @add-lead="openLeadModal"
    />
  </div>
</template>

<script>
  import iframeMixinx from "@/mixins/iframeMixin";
  import modalCreateLead from "@/components/Leads/addLead.vue"
  import modalViewLead from "@/components/Pipeline/ModalVisualizarLead.vue"
  export default {
    mixins: [iframeMixinx],
    components: {
      modalCreateLead,
      modalViewLead
    },
    data() {
      return {
        leadId: 0,
        entireLead: {},
        newLeadPhone: '',
        newLeadPicture: '',
        newPicture: '',
        newLeadName: ''
      }
    },
    methods: {
      updateDataIframe(dataKey, newValue){
        this.$refs['visualizar-lead'].updateDataIframeLead(dataKey, newValue)
      },
      reset() {
        this.leadId = 0
        this.newLeadPhone =  ''
        this.newLeadPicture = ''
        this.newPicture = ''
        this.newLeadName = ''
        this.entireLead = {}
      },
      viewLead(data) {
        this.leadId = data.leadId;
        this.entireLead = data.entireLead;
        if(this.entireLead.newPicture){
          this.newPicture = this.entireLead.newPicture;
        }
        this.$grModal.show("visualizar-lead",this);
      },
      createLead(data) {
        this.newLeadPhone = data.newLeadPhone
        this.newLeadPicture = data.newLeadPicture
        this.newLeadName = data.newLeadName ?? ''

        if(data.leadId){
          this.$grToast.toast("Lead cadastrado com sucesso", {
            title: "Cadastro manual",
            variant: "info",
            autoHideDelay: 3000,
            appendToast: true,
          });

          this.leadId = data.leadId;
          this.newPicture = data.newLeadPicture || this.newLeadPicture;
          this.$grModal.show("visualizar-lead",this);
          return;
        }
        this.$grModal.show("add-lead",this);
      },
      openLeadModal(data) {
        this.newPicture = data.newLeadPicture || this.newLeadPicture
        this.blockClose = data.newLeadPicture ? false : true
        this.leadId = data
        this.entireLead = ''
        if (this.leadId) {
          this.$grModal.show("visualizar-lead",this);
        } else {
          this.returnPostMensage({type: 'closeModal'})
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      next(false);
    },
  }
</script>

<style lang="scss">
body:has(#transparent-body) {
  background-color: transparent !important;
}
</style>